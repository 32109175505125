import{ CoreError } from '../Core'

declare var GLOBAL_BaseApiURL_Direct:string


// TODO(arch): is there a better/standard way to initialize this? (ie: encapsulate within class)
export function initPreRegistration() {
	return;
	
	// GARBAGE
	$('#__preRegistration #preRegisterForm').on( 'submit', async e => {
		// prevent form submission (NOTE: we handle in AJAX instead)
		e.preventDefault()
		e.stopPropagation()
		
		// validate
		const emailTextField:JQuery<HTMLElement> = $('#__preRegistration #emailTextField')
		let email:string|number|string[]|undefined = emailTextField.val()
		if( email instanceof String ){
			email = email.trim()
		}
		const errorLabel:JQuery<HTMLElement> = $('#__preRegistration #errorLabel')
		if( !email ){
			emailTextField.addClass( 'is-invalid' )
			emailTextField.addClass( 'was-validated' )
			errorLabel.text( "Please enter your email" )
			errorLabel.show() // NOTE: needed because error label is not a sibling of the input control
			emailTextField.trigger( 'focus' )
			return
		} else {
			emailTextField.removeClass( 'is-invalid' )
			emailTextField.removeClass( 'was-validated' )
			errorLabel.hide()
		}
		// submit
		let preRegisterButton = $('#__preRegistration #registerButton')
		preRegisterButton.empty()
		preRegisterButton.append('<div class=\"async-indicator\"></div>' )
		errorLabel.hide()
		function showError( message?:string ){
			emailTextField.addClass( 'is-invalid' )
			emailTextField.trigger( 'focus' )
			errorLabel.text( message ?? "Something went wrong. Please try again later." )
			errorLabel.show() // NOTE: needed because error label is not a sibling of the input control
			preRegisterButton.empty()
			preRegisterButton.text( 'Join' )
		}
		try {
			const response:Response = await fetch( `${GLOBAL_BaseApiURL_Direct}/account/subscribers`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					Email: email,
					Platform: 'Android',
				}),
			})
			if( !response.ok ){
				const statusCode:number = response.status
				if( 400 <= statusCode && statusCode < 500 ){
					const errorJSON:CoreError = await response.json()
					switch( errorJSON.ErrorCode ){
						case "InvalidFormat":
							console.log( `Could not pre-register '${email}'. Invalid email.` )
							showError( "Please enter a valid email" )
							break

						default:
							console.log( `Could not pre-register '${email}'. Status code: ${statusCode}` )
							showError()
					}
				} else {
					console.log( `Could not pre-register '${email}'. Status code: ${statusCode}` )
					showError()
				}
				return
			}

			preRegisterButton.text( 'Registered' )
			$('#__preRegistration .unregistered').hide()
			$('#__preRegistration .registered').show()
			console.log( `Pre-registered '${email}' successfully` )
			
		} catch( x ){
			console.log( `Could not pre-register ${email}. ERROR: ${x.message}` )
			showError()
		}
	})
}
